import { ComponentType, useEffect, useState, useRef } from "react"
import { addPropertyControls, ControlType } from "framer"

function withKey(Component, key): ComponentType {
    return (props) => {
        const btnRef = useRef(null)

        const handleKeyDown = (event) => {
            if (event.key == key) {
                console.log(Component)
                console.log(props)
                btnRef.current.click()
            }
        }

        useEffect(() => {
            document.addEventListener("keydown", handleKeyDown)

            return () => {
                document.removeEventListener("keydown", handleKeyDown)
            }
        }, [])

        return <Component {...props} ref={btnRef} />
    }
}

// Letters
export const withH = (Component): ComponentType => withKey(Component, "h")
